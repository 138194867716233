<template>
    <div id="workflow-schedule-form" class="cron-generator">
        <b-tabs @input="emitCronString" v-model="selectedOption" content-class="mt-3">
            <b-tab v-if="visibleHourly" @click="handleTabChange('hourly')" title="Hourly">
                <b-form @input="emitCronString">
                    Every <b-form-input v-model="$v.hourly.every.$model" type="text"></b-form-input> hour(s)
                </b-form>

                <Feedback
                    :state="validateRef('hourly.every')"
                    invalid="This field is required and must be between 1 and 23"
                ></Feedback>
            </b-tab>

            <b-tab v-if="visibleDaily" @click="handleTabChange('daily')" title="Daily">
                <b-form @input="emitCronString">
                    Every <b-form-input v-model="$v.daily.every.$model" type="text"></b-form-input> day(s)

                    <Feedback
                        :state="validateRef('daily.every')"
                        invalid="This field is required and must be between 1 and 30"
                    ></Feedback>

                    <b-form-group
                        class="time-form-group"
                        label-size="sm"
                        label="Starts at"
                        :label-cols="3"
                        label-for="input-sm"
                    >
                        <b-row>
                            <b-col md="5">
                                <b-form-select
                                    @input="emitCronString"
                                    v-model="startsAt.hour"
                                    :options="options.hour"
                                    size="sm"
                                    class="mt-3"
                                ></b-form-select>
                            </b-col>
                            <b-col md="5">
                                <b-form-select
                                    @input="emitCronString"
                                    v-model="startsAt.minute"
                                    :options="options.minute"
                                    size="sm"
                                    class="mt-3"
                                ></b-form-select>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-tab>

            <b-tab v-if="visibleWeekly" @click="handleTabChange('weekly')" title="Weekly">
                <b-form @input="emitCronString">
                    <b-form-group>
                        <b-form-checkbox-group
                            v-model="weekly.every"
                            :options="options.weekly"
                            @input="emitCronString"
                        ></b-form-checkbox-group>
                    </b-form-group>

                    <b-form-group
                        class="time-form-group"
                        label-size="sm"
                        label="Starts at"
                        :label-cols="3"
                        label-for="input-sm"
                    >
                        <b-row>
                            <b-col md="5">
                                <b-form-select
                                    @input="emitCronString"
                                    v-model="startsAt.hour"
                                    :options="options.hour"
                                    size="sm"
                                    class="mt-3"
                                ></b-form-select>
                            </b-col>
                            <b-col md="5">
                                <b-form-select
                                    @input="emitCronString"
                                    v-model="startsAt.minute"
                                    :options="options.minute"
                                    size="sm"
                                    class="mt-3"
                                ></b-form-select>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-tab>

            <b-tab v-if="visibleMonthly" @click="handleTabChange('monthly')" title="Monthly">
                <b-form @input="emitCronString">
                    Day <b-form-input v-model="$v.monthly.every.days.$model" type="text"></b-form-input>
                    <Feedback
                        :state="validateRef('monthly.every.days')"
                        invalid="This field is required and must be between 1 and 30"
                    ></Feedback>

                    of every <b-form-input v-model="$v.monthly.every.months.$model" type="text"></b-form-input> month(s)
                    <Feedback
                        :state="validateRef('monthly.every.months')"
                        invalid="This field is required and must be between 1 and 12"
                    ></Feedback>

                    <b-form-group
                        class="time-form-group"
                        label-size="sm"
                        label="Starts at"
                        :label-cols="3"
                        label-for="input-sm"
                    >
                        <b-row>
                            <b-col md="5">
                                <b-form-select
                                    @input="emitCronString"
                                    v-model="startsAt.hour"
                                    :options="options.hour"
                                    size="sm"
                                    class="mt-3"
                                ></b-form-select>
                            </b-col>
                            <b-col md="5">
                                <b-form-select
                                    @input="emitCronString"
                                    v-model="startsAt.minute"
                                    :options="options.minute"
                                    size="sm"
                                    class="mt-3"
                                ></b-form-select>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-tab>

            <b-tab v-if="visibleEveryXMinutes" @click="handleTabChange('everyXMinutes')" title="Every X Minutes">
                <b-form @input="emitCronString">
                    Every <b-form-input v-model="$v.everyXMinutes.every.$model" type="text"></b-form-input> minute(s)
                </b-form>

                <Feedback
                    :state="validateRef('everyXMinutes.every')"
                    invalid="This field is required and must be between 1 and 59"
                ></Feedback>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Vue from 'vue'

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import {between, required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback'

export default {
    components: {
        Feedback,
    },
    props: {
        value: {
            type: String,
            default: undefined,
        },
        visibleHourly: {
            default: true,
        },
        visibleDaily: {
            default: true,
        },
        visibleWeekly: {
            default: true,
        },
        visibleMonthly: {
            default: true,
        },
        visibleEveryXMinutes: {
            default: true,
        },
    },
    data: () => {
        return {
            selectedOption: 0,
            hourly: {
                every: 1,
            },
            daily: {
                every: 1,
            },
            weekly: {
                every: [],
            },
            monthly: {
                every: {
                    days: 1,
                    months: 1,
                },
            },
            everyXMinutes: {
                every: '30',
            },
            startsAt: {
                hour: '12',
                minute: '00',
            },
            options: {
                weekly: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                hour: [
                    '00',
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                ],
                minute: [
                    '00',
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                    '24',
                    '25',
                    '26',
                    '27',
                    '28',
                    '29',
                    '30',
                    '31',
                    '32',
                    '33',
                    '34',
                    '35',
                    '36',
                    '37',
                    '38',
                    '39',
                    '40',
                    '41',
                    '42',
                    '43',
                    '44',
                    '45',
                    '46',
                    '47',
                    '48',
                    '49',
                    '50',
                    '51',
                    '52',
                    '53',
                    '54',
                    '55',
                    '56',
                    '57',
                    '58',
                    '59',
                ],
            },
        }
    },
    validations: {
        hourly: {
            every: {
                required,
                between: between(1, 23),
            },
        },
        daily: {
            every: {
                required,
                between: between(1, 29),
            },
        },
        monthly: {
            every: {
                days: {
                    required,
                    between: between(1, 30),
                },
                months: {
                    required,
                    between: between(1, 12),
                },
            },
        },
        everyXMinutes: {
            every: {
                required,
                between: between(1, 59),
            },
        },
    },
    watch: {
        value() {
            if (this.value) {
                this.buildFromCronString(this.value)
            }
        },
    },
    created() {
        const tabs = []
        if (this.visibleHourly) tabs.push('hourly')
        if (this.visibleDaily) tabs.push('daily')
        if (this.visibleWeekly) tabs.push('weekly')
        if (this.visibleMonthly) tabs.push('monthly')
        if (this.visibleEveryXMinutes) tabs.push('everyXMinutes')

        this.tabs = tabs

        if (this.value) {
            this.buildFromCronString(this.value)
        } else {
            const startsAtMinute = this.startsAt.minute[0] == '0' ? this.startsAt.minute[1] : this.startsAt.minute
            const startsAtHour = this.startsAt.hour[0] == '0' ? this.startsAt.hour[1] : this.startsAt.hour
            const dayOfWeekMap = {Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6}

            const firstTab = tabs[0]
            this.selectedTab = firstTab

            let cron = '* * * * *'
            if (firstTab === 'hourly') {
                cron = `0 */${this.hourly.every} * * *`
                this.$emit('onScheduleChange', cron)
            }

            if (firstTab === 'daily') {
                cron = `${startsAtMinute} ${startsAtHour} */${this.daily.every} * *`
                this.$emit('onScheduleChange', cron)
            }

            if (firstTab === 'weekly') {
                const weeklyEvery = this.weekly.every.map(day => dayOfWeekMap[day]).join(',')
                cron = `${startsAtMinute} ${startsAtHour} * * ${weeklyEvery}`
                this.$emit('onScheduleChange', cron)
            }

            if (firstTab === 'monthly') {
                cron = `${startsAtMinute} ${startsAtHour} ${this.monthly.every.days} */${this.monthly.every.months} *`
                this.$emit('onScheduleChange', cron)
            }
        }
    },
    methods: {
        handleTabChange(target) {
            this.selectedTab = target
        },
        emitCronString(selectedTab) {
            const startsAtMinute = this.startsAt.minute[0] == '0' ? this.startsAt.minute[1] : this.startsAt.minute
            const startsAtHour = this.startsAt.hour[0] == '0' ? this.startsAt.hour[1] : this.startsAt.hour
            const dayOfWeekMap = {Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6}

            let cron = '* * * * *'

            if (this.selectedTab === 'hourly') {
                cron = `0 */${this.hourly.every} * * *`
            }

            if (this.selectedTab === 'daily') {
                cron = `${startsAtMinute} ${startsAtHour} */${this.daily.every} * *`
            }

            if (this.selectedTab === 'weekly') {
                const weeklyEvery = this.weekly.every.map(day => dayOfWeekMap[day]).join(',')
                cron = `${startsAtMinute} ${startsAtHour} * * ${weeklyEvery}`
            }

            if (this.selectedTab === 'monthly') {
                cron = `${startsAtMinute} ${startsAtHour} ${this.monthly.every.days} */${this.monthly.every.months} *`
            }

            if (this.selectedTab === 'everyXMinutes') {
                cron = `*/${this.everyXMinutes.every} * * * *`
            }

            this.$emit('onScheduleChange', cron)
            this.$emit('input', cron)
        },
        formatCronNumberToString(number) {
            return Number(number).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
                useGrouping: false,
            })
        },
        buildFromCronString(cron) {
            const cronArray = cron.split(' ')

            if (cronArray[0] === '*') {
                this.selectedTab = 'hourly'
                this.selectedOption = 0
                this.hourly.every = Number(cronArray[1].split('/')[1])
            } else {
                this.startsAt.minute = this.formatCronNumberToString(cronArray[0])
                this.startsAt.hour = this.formatCronNumberToString(cronArray[1])
            }

            if (cronArray[0].includes('/')) {
                this.selectedTab = 'everyXMinutes'
                this.selectedOption = 4
                this.everyXMinutes.every = Number(cronArray[0].split('/')[1])
            }

            if (cronArray[2].includes('/')) {
                this.selectedTab = 'daily'
                this.selectedOption = 1
                this.daily.every = Number(cronArray[2].split('/')[1])
            }

            if (cronArray[3].includes('/')) {
                this.selectedTab = 'monthly'
                this.selectedOption = 3
                this.monthly.every.days = Number(cronArray[2])
                this.monthly.every.months = Number(cronArray[3].split('/')[1])
            }

            if (cronArray.every(el => !el.includes('/'))) {
                this.selectedTab = 'weekly'
                this.selectedOption = 2

                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                this.weekly.every = cronArray[4].split(',').map(el => daysOfWeek[el])
            }
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>

<style lang="scss">
.cron-generator {
    .nav-tabs {
        border-bottom: 1px solid #e8e8e8;
        font-weight: 600;
        font-size: 0.8rem;
        .nav-link.active {
            border-color: #e8e8e8 #e8e8e8 transparent;
            border-radius: 5px;
        }
    }

    .tab-content {
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        font-weight: 500;
        form:nth-child(1) {
            label:nth-child(n) {
                font-weight: 500;
            }
            .time-form-group {
                label {
                    margin-top: 0.8rem;
                }
            }
        }
    }
}
</style>
